<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="isOld && !disabled && editable"
            :url="completeUrl"
            :isSubmit="isComplete"
            :param="check"
            mappingType="PUT"
            label="완료"
            icon="check"
            @beforeAction="completeRegularCheck"
            @btnCallback="completeCallback" 
          />
          <c-btn
            v-show="isOld && !disabled && editable" 
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="remove" />
          <c-btn
            v-show="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="check"
            :mappingType="mappingType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveRegularCheck"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-danger-area
            required
            :editable="editable"
            :disabled="disabled || isOld"
            label="위험물 저장소"
            name="chmDangerMstId"
            v-model="check.chmDangerMstId"
            @detailInfo="detailInfo"
          ></c-danger-area>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :disabled="true"
            type="edit"
            codeGroupCd="CHM_DANGER_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            label="구분"
            v-model="check.chmDangerTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            required
            :editable="editable"
            :disabled="disabled"
            :data="check"
            label="점검자"
            type="user"
            name="chmDangerInspectorUserId"
            v-model="check.chmDangerInspectorUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true"
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="점검기간"
            name="period"
            v-model="check.period"
            @datachange="changeDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="점검명"
            name="chmDangerCheckName"
            v-model="check.chmDangerCheckName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :editable="editable"
            :disabled="disabled"
            type="edit" 
            name="plantCd" 
            v-model="check.plantCd" />
        </div>
      </template>
    </c-card>
    <c-table
      ref="grid"
      title="정기점검항목 목록"
      :merge="grid.merge"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="check.checkResults"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="점검항목을 추가하세요."
      :editable="editable && !disabled"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable && !disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="chmDangerRegularCheckResultId"
            ibmTaskTypeCd="ITT0000205"
            ibmTaskUnderTypeCd="ITTU000235"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
  </q-form>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerMaintenanceInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
      }),
    },
    check: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
        chmDangerMstId: '',
        chmDangerMstName: '',
        chmDangerMstTypeId: '',
        plantCd: '',
        chmDangerTypeCd: null,
        chmDangerLocation: '',
        chmDangerCheckName: '',
        chmDangerPermitNo: '',
        chmDangerUserId: '',
        chmDangerManageUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerInspectorUserId: '',
        chmStatusDangerRegularCheckCd: '',
        chmDangerCount: '',
        installPermitDate: '',
        chmDangerHandlingOverview: '',
        chmDangerStartDate: '',
        chmDangerEndDate: '',
        period: [],
        checkResults: [],
        deleteCheckResults: [],
        types: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'dangerCheckItem' }
        ],
        columns: [
         {
            name: 'dangerCheckItem',
            field: 'dangerCheckItem',
            label: '점검항목',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'dangerCheckContent',
            field: 'dangerCheckContent',
            label: '점검내용',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'dangerCheckMethod',
            field: 'dangerCheckMethod',
            label: '점검방법',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'dangerCheckResult',
            field: 'dangerCheckResult',
            // 점검결과
            label: 'LBL00001620',
            setHeader: true,
            align: 'center',
            style: 'width:100px',
            type: 'select',
            codeGroupCd: 'CHM_DANGER_REGULAR_CHECK_RESULT_CD',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'dangerCheckRemark',
            field: 'dangerCheckRemark',
            // 비고
            label: 'LBLREMARK',
            align: 'left',
            type: 'textarea',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선/진행상태
            label: 'LBL00001621',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
            sortable: false
          },
        ],
        height: '300px',
        data: [],
      },
      editable: true,
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 350;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
    requestContentsCols() {
      return ['dangerCheckItem'];
    },
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.chm.danger.regularCheck.update.url;
      this.completeUrl = transactionConfig.chm.danger.regularCheck.complete.url;
      this.deleteUrl = transactionConfig.chm.danger.regularCheck.delete.url;
      // code setting
      // list setting
    },
    detailInfo(data) {
      if (this.isOld) return;
      let validDate = this.check.period && this.check.period.length === 2 && this.check.period[0]
      this.$_.extend(this.check, this.$_.extend(data, {
        chmDangerCheckName: validDate ? `[${this.check.period.join(' ~ ')}] ${data.chmDangerMstName}` : this.check.chmDangerCheckName,
        chmDangerLocation: data.chmDangerArea,
        chmDangerPermitNo: data.permitNo,
        chmDangerManageUserId: data.chmDangerManageUserId,
        chmDangerUserId: data.chmDangerUserId,
        checkitems: data.typeList,
        checkResults: this.$_.map(data.itemList, item => {
          return this.$_.extend(item, {
            dangerCheckResult: 'CDRCRC0001',
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
      }))
    },
    changeDate() {
      if (this.check.period 
        && this.check.period.length === 2 
        && this.check.period[0] 
        && this.check.chmDangerMstId) {
          this.$set(this.check, 'chmDangerCheckName', `[${this.check.period.join(' ~ ')}] ${this.check.chmDangerMstName}`)
      }
    },
    saveRegularCheck() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.chm.danger.regularCheck.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.chm.danger.regularCheck.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.check.checkResults)) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.regUserId = this.$store.getters.user.userId
              this.check.chgUserId = this.$store.getters.user.userId

              if (this.check.period && this.check.period.length === 2) {
                this.check.chmDangerStartDate = this.check.period[0]
                this.check.chmDangerEndDate = this.check.period[1]
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if(!this.popupParam.chmDangerRegularCheckId) {
        this.popupParam.chmDangerRegularCheckId = result.data;
      }
      this.$emit('getDetail');
    },
    completeRegularCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
    /* eslint-disable no-unused-vars */
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.chmDangerRegularCheckId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    imprChange() {
      this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
